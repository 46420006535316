<template>
  <div id="works" class="contents">
    <MessageBox ref="msgBox" />
    <h1 title="実績">WORKS</h1>

    <div class="intro">
      <h2>実績紹介</h2>
      <p>弊社では、ゲーム開発以外にも様々なお手伝いをさせていただいてます。<br class="no-sp">
お見せできる情報は一部ですので、詳細はお気軽にお問い合わせください。</p>
    </div>

    <div class="content">
      <section>
        <div class="fillter">
          <div class="sticky">
            <h2>{{ activeCategory }}</h2>
            <ul>
              <li><router-link to="/works">全て</router-link></li>
              <li v-for="cat in categories" :key="cat.id"><router-link :to="{ name:'works', params: {id: cat.id}}">{{ cat.name }}</router-link></li>
            </ul>
          </div>
        </div>
        <div class="post">
          <transition name="fade" mode="out-in">
          <div v-if="!categoryPostDatas[this.catId] || categoryPostDatas[this.catId].length == 0" class="nodata">
            このカテゴリは準備中です。<br>しばらくお待ちください。
          </div>

          <transition-group name="fade" mode="out-in">
          <article v-for="data in categoryPostDatas[this.catId]" :key="data.id">
            <h3>{{ data.title }}</h3>
              <ul class="label">
                <li v-for="cat in data.category" :key="cat.index" v-html="getCategoryName(cat)" />
                <li v-html="getWorksDate(data.date)" />
                <li>
                  {{data.place}} 様
                </li>
              </ul>
              <div :class="['body', {nophoto: !data.image}]" v-if="data.image || data.description">
                <!-- <img v-if="data.image" :src="data.image" > -->
                <silent-box :gallery="data.image" v-if="data.image"></silent-box>
                <p v-html="data.description" />
              </div>
              <div class="tag">
                <ul class="engine">
                  <li>
                    <div class="img"><img :src="getIconUrl(data.engine.value)" :alt="data.engine.label" class="desvg"></div>
                    {{data.engine.label}}
                  </li>
                </ul>
                <ul class="expression">
                  <li v-for="data in data.expression" :key="data.index">
                    <div class="img"><img :src="getIconUrl(data.value)" alt=""></div>
                    {{data.label}}
                  </li>
                </ul>
                <ul class="field">
                  <li v-for="data in data.field" :key="data.index">
                    <div class="img"><img :src="getIconUrl(data.value)" alt=""></div>
                    {{data.label}}
                  </li>
                </ul>
                <ul class="player">
                  <li v-for="data in data.player" :key="data.index">
                    <div class="img"><img :src="getIconUrl(data.value)" alt=""></div>
                    {{data.label}}
                  </li>
                </ul>
                <ul class="input">
                  <li v-for="data in data.input" :key="data.index">
                    <div class="img"><img :src="getIconUrl(data.value)" alt=""></div>
                    {{data.label}}
                  </li>
                </ul>
                <ul class="output">
                  <li v-for="data in data.output" :key="data.index">
                    <div class="img"><img :src="getIconUrl(data.value)" alt=""></div>
                    {{data.label}}
                  </li>
                </ul>
              </div>
              <ul class="other">
                <li v-for="data in data.other" :key="data.index">
                  {{data.label}}
                </li>
              </ul>
          </article>
          </transition-group>
          </transition>
        </div>
      </section>
    </div>

    <ToTop />
    <ToContact />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'

export default {
  name: 'Works',
  data: function () {
    return {
      baseAPIUrl: 'https://sorashiro.com/wp-json/wp/v2/',
      activeCategory: '',
      postData: [],
      categories: [],
      categoryPostDatas: [],
      catId: 0
    }
  },
  metaInfo: {
    title: '実績',
    bodyAttrs: {
      id: 'pg-works'
    },
    meta: [
      { name: 'description', content: 'ソラシロが過去に制作した実績の一部になります。様々な業種、ご要望に合わせたプラットフォームでの実績がございます。詳しい内容についてはお問い合わせください。' }
    ],
    link: [
      { rel: 'canonical', href: 'https://sorashiro.com/works/' }
    ]
  },
  created: function () {
    if (this.$route.params.id) {
      this.catId = this.$route.params.id
    }
    this.loadPostData()
  },
  mounted: function () {
    this.$refs.msgBox.show('loading', '')
  },
  computed: {
    categoryPostData () {
      var catId = this.$route.params.id
      if (catId) {
        return this.categoryPostDatas[catId]
      }
      return this.postData

      // return function (catId) {
      // console.log(this.postData)
      // var r = []
      // if (catId) {
      //  for (var post of this.postData) {
      //    console.log(post.category)
      //    for (const key in post.category) {
      //      if (post.category[key] === catId) {
      //        r.push(post)
      //        break
      //      }
      //    }
      //  }
      // } else {
      //  r = this.postData
      // }
      // return r
      // }
    },
    nl2br () {
      return function (content) {
        return content.replace(/\n/g, '<br>')
      }
    }
  },
  methods: {
    init: function () {
      this.$refs.msgBox.show('', '')
      // this.initGsap()
      this.$nextTick(function () {
        this.initGsap(this.$isMobile())
      })
    },
    getWorksDate: function (d) {
      var r = new Date(d)
      r = r.getFullYear() + '.' + (r.getMonth() + 1)
      return r
    },
    getCategoryName: function (id) {
      if (id) {
        for (var k in this.categories) {
          if (this.categories[k].id === id) {
            return this.categories[k].name
          }
        }
      }
      return '全て'
    },
    loadPostData: function () {
      const url1 = this.baseAPIUrl + 'works'
      const url2 = this.baseAPIUrl + 'category_works'
      var tempPost = []
      var tempCat = []

      Promise.resolve()
        .then(() => axios.get(url1))
        .then((response) => {
          // 1個目の通信が成功
          for (var post of response.data) {
            console.log(response.data)
            var images = []
            if (post.acf.img) {
              for (var imgPath of post.acf.img) {
                images.push({
                  src: this.$store.getters.baseUrl + imgPath
                })
              }
            } else images = null

            var data = {
              id: post.id,
              title: post.title.rendered,
              date: post.date,
              category: post.category_works,
              description: post.acf.description,
              place: post.acf.place,
              // image: (post.acf.img) ? this.$store.getters.baseUrl + post.acf.img_main : '',
              image: images,
              field: post.acf.field,
              engine: post.acf.engine,
              expression: post.acf.expression,
              player: post.acf.player,
              os: post.acf.os,
              input: post.acf.input,
              output: post.acf.output,
              other: post.acf.other
            }
            tempPost.push(data)
          }
          this.postData = tempPost
          return axios.get(url2)
        })
        .then(response => {
          // 2個目の通信が成功
          for (var post of response.data) {
            var data = {
              id: post.id,
              name: post.name
            }
            tempCat.push(data)
          }
          this.categories = tempCat
        })
        .catch(e => {
          // エラーが発生
          console.log(e)
        })
        .finally(() => {
          // 最終的な処理を書く
          this.categoryPostDatas[0] = []
          if (this.postData) {
            this.categoryPostDatas[0] = this.postData
          }
          for (var category of this.categories) {
            this.categoryPostDatas[category.id] = []
            for (var post of this.postData) {
              for (const key in post.category) {
                if (post.category[key] === category.id) {
                  this.categoryPostDatas[category.id].push(post)
                  break
                }
              }
            }
          }
          console.log(this.categoryPostDatas)
          this.activeCategory = this.getCategoryName(this.$route.params.id)
          this.init()
        })
    },
    getIconUrl (fileName) {
      // console.log(fileName)
      // .jpgのみ想定
      const images = require.context('../assets/icons/', false, /\.svg$/)

      // 3.jpg、 6.jpgのような画像を想定
      return images('./' + fileName + '.svg')
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id) {
      this.catId = to.params.id
    } else {
      this.catId = 0
    }
    this.activeCategory = (to.params.id) ? this.getCategoryName(to.params.id) : '全て'
    next()
  },
  beforeDestroy: function () {
    this.removeGsap()
  }
}
</script>

<style lang="scss" scoped>

  section{
    .nodata{
      font-size: 1.2em;
      text-align: center;
      font-weight: 200;
      min-height: 30vh;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    article{
      flex: 1;
      margin-bottom: 6em;
      position: relative;
      h3{
        font-size: 1.7em;
        font-weight: 300;
        display: inline-block;
        background-image: none;
        top: 0;
        width: auto;
        height: auto;
        position: relative;
        margin: 0;
        padding: 0 .8em 0 .4em;
        border-bottom: 1px solid $hmd_color;

        &:before{
          content: "";
          position: absolute;
          border-bottom: 1px solid $hmd_color;
          transform: rotateZ(45deg);
          transform-origin: right;
          width: 15px;
          right: 100%;
          bottom: -1px;
        }
        &:after{
          display: none;
        }

        // !Mobile
        @include mq-max() {
          font-size: 1.4em;
        }
      }

      .label{
        margin: 1em;
        padding: 0;
        font-size: 0.8em;
        // display: flex;
        // flex-wrap: wrap;

        li{
          display: inline-block;
          white-space: nowrap;
          line-height: 1em;
          // white-space: nowrap;
          font-weight: bold;
          position: relative;
          top: -1px;
          left: -1px;
          // background-color: #fff;
          // border: 1px solid #fff;
          padding: .2em .6em .2em 0;
          // color: white;
          &:after{
            content: "/";
            color: $hmd_color;
            display: inline-block;
            padding-left: .6em;
            opacity: .5;
          }
          &:last-child{
            &:after{
              content: "";
            }
          }
        }

        // !Mobile
        @include mq-max() {
          text-align: center;
        }
      }

      .body{
        overflow: auto;
        display: grid;
        grid-template-columns: 180px 2fr;
        grid-template-areas: "img description";
        margin-top: 1em;
        &.nophoto{
          display: block;
          p{
            padding: 0;
          }
        }
        img{
          grid-area: img;
          width: 100%;
          object-fit: cover;
        }
        p{
          margin: 1em .5em;
          grid-area: description;
        }
      }

      .tag{
        ul{
          display: inline;
          // flex-wrap: wrap;
          padding: 0;
          margin: 0;
          list-style: none;
          width: auto;

          li{
              position: relative;
              display: inline-block;
              font-size: 0.8em;
              text-align: center;
              margin: 10px 8px 8px 0;
              font-weight: bold;
              white-space: nowrap;
              vertical-align: top;
              color: lighten($base-color,10);

            .img{
              width: 50px;
              height: 50px;
              padding: 10px;
              // border: 1px solid rgba($base-color, .3);
              border: 1px solid $hmd_color;
              background: rgb(255 255 255 / 80%);
              border-radius: 50%;
              margin: 0 auto .2em auto;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            img{
              width: 100%;
              height: 100%;
              display: block;
              margin: auto;
              // filter: drop-shadow(0px 0px 4px #fff);
            }

          }

        }
          // !Mobile
            @include mq-max() {
            white-space: nowrap;
            overflow-x: auto;
          }
      }
          ul.other{
              display: inline;
              // flex-wrap: wrap;
              padding: 0;
              margin: 0;
              list-style: none;
              width: auto;
              display: block;
              margin-top: 1em;
            li{
              font-size: 0.8em;
              width: auto;
              height: auto;
              display: inline-block;
              border-radius: 10px;
              font-weight: 500;
              padding: .1em .8em;
              margin: 0 3px 3px 0;
              border: 1px solid rgba($hmd_color, .3);
              background: rgb(255 255 255 / 80%);
            }
          }
    }
  }
</style>

<style lang="scss">

  #silentbox-gallery{
    margin-bottom: initial;
    width: initial;
    max-width: initial;
    margin: initial;
    position: initial;
    display: initial;
    justify-content: initial;

    .silentbox-item{
      display: none;
      &:first-child{
        display: inline-block;
      }

      img{
        width: 170px;
        height: 170px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

</style>
